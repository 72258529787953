<template>
  <base-section id="first-vault-features">
    <base-section-heading title="FirstVault Features"> </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            customText="Features"
          >
            -----
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "FirstVaultFeatures",

  data: () => ({
    features: [
      {
        title: "End-to-end encrypted",
        image: "encryption",
        text: "Data is securely encoded at the source and only decoded by the intended recipient. This ensures that information remains private throughout its journey, safeguarding it from unauthorized access.",
      },
      {
        title: "Encrypted database to avoid breach",
        image: "data-security",
        text: "Utilize an encrypted database to fortify data protection. This shields information from breaches by rendering it unreadable to unauthorized parties, ensuring a secure environment for your valuable data.",
      },
      {
        title: "Active Directory & MFA",
        image: "two-factor-authentication",
        text: "Centralized user management via Active Directory streamlines access control. Multi-Factor Authentication (MFA) enhances security by requiring multiple verification steps, reducing the risk of unauthorized entry.",
      },
      {
        title: "Admin Portal",
        image: "admin-panel",
        text: "Effortlessly oversee employee vaults and service credentials through a dedicated admin portal. This provides streamlined control over data access and management for enhanced security and convenience.",
      },
      {
        title: "IOT integration",
        image: "internet-of-things",
        text: "Easily and securely retrieve API keys and credentials for seamless integration with Internet of Things (IoT) devices. This ensures smooth communication while maintaining data security and privacy.",
      },
      {
        title: "Service account management",
        image: "accountant",
        text: "Efficiently handle service accounts' permissions and credentials. This enables smooth coordination among different services while maintaining a robust security framework.",
      },
      {
        title: "Password & File sharing",
        image: "social-engineering",
        text: "Seamlessly share passwords and files with colleagues while maintaining security. This feature enables collaboration without compromising sensitive information, fostering efficient teamwork within a protected framework.",
      },
      {
        title: "Export your secure vault",
        image: "export",
        text: "Effortlessly export your safeguarded vault contents. This empowers you to securely relocate and manage your encrypted data, ensuring flexibility and control without compromising on security.",
      },
      {
        title: "Privilege Access Management",
        image: "access-control",
        text: "Effectively control and monitor privileged access to sensitive resources. This safeguards against unauthorized usage and potential breaches, ensuring a strong security posture across the platform.",
      },
      {
        title: "Password Generator & Autofill",
        image: "password",
        text: "Generate strong passwords and automate their insertion. This feature enhances security by creating robust passwords and streamlining their usage, reducing the risk of unauthorized access.",
      },
      {
        title: "Access historic passwords",
        image: "reset-password",
        text: "Retrieve all historical passwords and notes effortlessly. This feature enables easy access to past data, promoting organization and convenience without compromising on security.",
      },
      {
        title: "Strict GDPR Compliance",
        image: "gdpr",
        text: "Adhere to strict GDPR regulations for data protection. This ensures that user privacy is respected, fostering trust and compliance with international data security standards.",
      },
    ],
  }),
};
</script>
